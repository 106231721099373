import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from 'gatsby'

class ContactPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Seo
          title="Contact"
          keywords={[
            "blog",
            "javascript",
            "typescript",
            "contractor",
            "software",
            "engineer",
            "react",
            "react-native",
            "mobile",
            "app",
          ]}
        />
        <h1>
          If you want to reach out
        </h1>
        <p>
          Send me an email at{" "}
          <span style={{ fontWeight: "bold" }}>adamczyk.jk [at] gmail.com</span>
        </p>
        <p>In the meantime feel free to follw me on social media.</p>
        <ul>
          <li>
            <a href="https://twitter.com/jkadamczyk">Twitter</a>
          </li>
          <li>
            <a href="https://github.com/jkadamczyk">GitHub</a>
          </li>
          <li>
            <a href="https://www.linkedin.com/in/jkadamczyk/">LinkedIn</a>
          </li>
        </ul>
      </Layout>
    )
  }
}

export default ContactPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
